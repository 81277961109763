import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="container" data-layout="container">
      <div className="row flex-center min-vh-100 py-6 text-center">
        <div className="col-sm-10 col-md-8 col-lg-6 col-xxl-5">
          <a className="d-flex flex-center mb-4" href="https://nimakouchaki.com/">
            <img
              className="me-2"
              src={
                window.origin + "/assets/img/icons/spot-illustrations/logo.png"
              }
              alt
              width={58}
            />
            <span className="font-sans-serif fw-bolder fs-5 d-inline-block">
              نیما کوچکی
            </span>
          </a>
          <div className="card">
            <div className="card-body p-4 p-sm-5">
              <div className="fw-black lh-1 text-300 fs-error">404</div>
              <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold w-md-75 w-xl-100 mx-auto">
                صفحه ای که به دنبال آن هستید یافت نشد.
              </p>
              <hr />
              <p>
                مطمئن شوید که آدرس صحیح است و صفحه جابجا نشده است. اگر فکر می
                کنید این یک اشتباه است،{" "}
                <a href="mailto:info@exmaple.com">با ما تماس بگیرید</a> .
              </p>
              <Link className="btn btn-primary btn-sm mt-3" to={"/"}>
                <span className="fas fa-home me-2" />
                من رو ببر خونه
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
