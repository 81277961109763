import React from "react";
import { ToDateJalali } from "../../../../Utils/functions";

const Notifications = ({ data }) => {
  return (
    <div className="col-md-12 ">
      <div className="card h-100">
        <div className="card-header">
          <div className="d-flex align-items-center">
            <img
              className="me-2"
              src="../assets/img/icons/signal.png"
              alt
              height={35}
            />
            <h5 className="fs-0 fw-normal text-800 mb-0">اطلاعیه ها</h5>
          </div>
        </div>
        <div className="card-body p-1">
          <div className=" pt-0 px-card ">
            {data.notif.length < 1 ? (
              <p className="text-center">اطلاعیه ای وجود ندارد</p>
            ) : (
              data?.notif.map((n) => {
                return (
                  <div className="border border-1 border-300 rounded-2 p-3 ask-analytics-item position-relative mb-3">
                    <div className="d-flex align-items-center mb-3">
                      <span className="fas fa-code-branch text-primary" />
                      <a
                        className="stretched-link text-decoration-none"
                        href="#!">
                        <h5 className="fs--1 text-600 mb-0 ps-3">{n?.title}</h5>
                      </a>
                    </div>
                    <h5 className="fs--1 text-800">{n?.content}</h5>
                    <h5 className="fs--1 text-800">
                      {ToDateJalali(n?.created_at)}
                    </h5>
                  </div>
                );
              })
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notifications;
