import React, { Component, useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { API_SERVER, post } from "../../../../Server";
const TextEditor = ({ element, handleChange, handleValue }) => {
  const UPLOAD_ENDPOINT = "/uploadImage";
  function uploadAdapter(loader) {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then(async file => {
            body.append("image", file);
            const { data } = await post(UPLOAD_ENDPOINT, { image: file }, true);
            resolve({ default: `${data[0]}` });
          });
        });
      },
    };
  }

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = loader => {
      return uploadAdapter(loader);
    };
  }
  const editorConfiguration = {
    height: "350",
    row: 20,
    extraPlugins: [uploadPlugin],
    language: "fa",
    toolbar: {
      items: [
        "heading",
        "|",
        "fontSize",
        // "fontFamily",
        "|",
        "bold",
        "italic",
        "|",
        "alignment",
        "|",
        "numberedList",
        "bulletedList",
        "|",
        "indent",
        "outdent",
        "|",
        "link",
        "blockQuote",
        "imageUpload",
        "insertTable",
        "mediaEmbed",
        "|",
        "undo",
        "redo",
        "placeholder",
        "|",
        "codeblock",
      ],
    },
    image: {
      toolbar: [
        "imageTextAlternative",
        "imageStyle:full",
        "imageStyle:side",
        "linkImage",
        "resizeImage",
      ],
      resizeOptions: [
        {
          name: "resizeImage:original",
          value: null,
          label: "Original",
        },

        {
          name: "resizeImage:10",
          value: "10",
          label: "10%",
        },
        {
          name: "resizeImage:20",
          value: "20",
          label: "20%",
        },

        {
          name: "resizeImage:30",
          value: "30",
          label: "30%",
        },
        {
          name: "resizeImage:40",
          value: "40",
          label: "40%",
        },
        {
          name: "resizeImage:50",
          value: "50",
          label: "50%",
        },
        {
          name: "resizeImage:60",
          value: "60",
          label: "60%",
        },
        {
          name: "resizeImage:70",
          value: "70",
          label: "70%",
        },
        {
          name: "resizeImage:80",
          value: "80",
          label: "80%",
        },
        {
          name: "resizeImage:90",
          value: "90",
          label: "90%",
        },
      ],
    },
    table: {
      contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
    },
    placeholderConfig: {
      types: ["Name", "DOB"],
    },
    mediaEmbed: {
      extraProviders: [
        {
          name: "extraProvider",
          url: /^aparat\.com/,
          html: match => {
            return `<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/${
              match.input.split("/v/")[1]
            }/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`;
            return `<div id="52739075379"><script type="text/JavaScript" src="https://www.aparat.com/embed/uO6MI?data[rnddiv]=52739075379&data[responsive]=yes"></script></div>`;
            return `<div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/${
              match.input.split("/v/")[1]
            }/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`;
            return `<style>.h_iframe-aparat_embed_frame{position:relative;}.h_iframe-aparat_embed_frame .ratio{display:block;width:100%;height:auto;}.h_iframe-aparat_embed_frame iframe{position:absolute;top:0;left:0;width:100%;height:100%;}</style><div class="h_iframe-aparat_embed_frame"><span style="display: block;padding-top: 57%"></span><iframe src="https://www.aparat.com/video/video/embed/videohash/uO6MI/vt/frame" allowFullScreen="true" webkitallowfullscreen="true" mozallowfullscreen="true"></iframe></div>`;
          },
        },
      ],
    },
  };
  return (
    <div className={`my-2 ${element.size || "col-12"}`}>
      <label htmlFor="">{element.label}</label>

      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        onChange={(event, editor) => {
          const data = editor.getData();
          handleChange(element.key, data);
        }}
        data={handleValue(element.key)}
      />
    </div>
  );
};

export default TextEditor;
