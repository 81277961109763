import React from "react";
import Notifications from "./Components/Notifications";
import Results from "./Components/Results";
import { useDashboard } from "./Hooks";

const Expertor = () => {
  // sexpert
  const { data, loading } = useDashboard({
    api: { route: "/sexpert/dashboard" },
  });
  if (loading) {
    return <>Loading ...</>;
  }
  return (
    <div>
      <div className="row mb-3">
        <Notifications data={data} />
        <div className="col-12 mt-3">
          <div className="card py-3 mb-3">
            <div className="card-body py-3">
              <div className="row g-0">
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700">تعداد کل </h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700"> در حال انجام </h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_inprocses_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700"> متوقف شده</h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_wait_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700"> اغاز نشده</h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_notstart_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700"> تمام شده</h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_done_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700">ناموفق</h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_notdone_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700">نیا به تلاش مجدد</h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_try_again_crm}
                  </p>
                </div>
                <div className="col-4 col-md-3 border-200 border-bottom border-end pb-4">
                  <h6 className="pb-1 text-700">کنسل شده</h6>
                  <p className="font-sans-serif lh-1 mb-1 fs-2">
                    {data?.count_canceled_crm}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Expertor;
