import React from "react";
import Table from "../Table";
import CardTable from "./Components/CardTable";
import Notifications from "./Components/Notifications";
import Plot from "./Components/Plots";
import Results from "./Components/Results";
import { useDashboard } from "./Hooks";
const Manager = () => {
  const { data, loading } = useDashboard({
    api: { route: "/manager/dashboard" },
  });
  if (loading) {
    return <>Loading ...</>;
  }
  return (
    <>
      <div className="row g-3 mb-3">
        <CardTable data={data} />
      </div>
      <div className="row mb-3">
        <Results data={data} />
      </div>
      <div className="row mb-3">
        <div className="col">
          <Plot label="درآمد هفتگی" data={data.chart} />
        </div>
      </div>
      <div className="row ">
        <Notifications data={data} />
      </div>
    </>
  );
};

export default Manager;
