import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useMainContext } from "../../../Context";
import CheckEditor from "../Components/CheckEditor";
import DatePicker from "../Components/DatePicker";
import FileUploader from "../Components/FileUploader";
import ImageUploader from "../Components/ImageUploader";
import Keywords from "../Components/Keywords";
import MultiSelect from "../Components/MultiSelect";
import Select from "../Components/Select";

export const useForm = ({
  route = "",
  elements = [],
  needs = [],
  sort = (state) => state,
  edit = false,
  show = {},
  sortInint = (state) => state,
  initial = {},
}) => {
  const { id = false } = useParams();
  const location = useLocation();

  const [form, setForm] = useState({ seo: {} });
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingEdit, setLoadingEdit] = useState(true);
  const [loadingNeed, setLoadingNeed] = useState(true);
  const {
    post,
    state: { user },
  } = useMainContext();
  console.log({ form });
  useEffect(() => {
    if (!edit) {
      setForm({ seo: {} });
    }
  }, [location.pathname]);
  useEffect(() => {
    if (needs.length > 0) {
      getNesdata();
    }
  }, []);
  useEffect(() => {
    if (edit) {
      editData();
    }
  }, [location.pathname]);

  const submit = async () => {
    if (check()["status"] || edit) {
      setLoading(true);
      await post(route, sort(form), true);
      setLoading(false);
    } else {
      renderError();
    }
  };
  const getNesdata = async () => {
    setLoadingNeed(false);
    const result = await post("/nesdata", { needkey: needs });
    setData(result.data);
    setLoadingNeed(false);
  };
  const editData = async () => {
    if (show.route) {
      setLoadingEdit(true);
      const result = await post(show.route, { [show.key]: id, ...initial });
      setForm(sortInint(result.data));
      setLoadingEdit(false);
    }
  };

  const handleChange = (key, value, nested = false) => {
    if (nested) {
      if (form[nested]) {
        setForm({ ...form, [nested]: { ...form[nested], [key]: value } });
      } else {
        setForm({
          ...form,
          [nested]: {
            [key]: value,
          },
        });
      }
    } else {
      setForm({ ...form, [key]: value });
    }
  };
  const handleValue = (key, nested = false) => {
    if (nested) {
      return (form[nested] && form[nested][key]) || "";
    } else {
      return form[key] || "";
    }
  };
  const check = () => {
    let result = true;
    let emptys = [];
    elements &&
      elements.map((element) => {
        if (Array.isArray(element.key)) {
          element.key.map((key) => {
            if (key in form) {
              if (!form[key]) {
                result = false;
                emptys.push(element.label);
              }
            } else {
              result = false;
              emptys.push(element.label);
            }
          });
        } else {
          if (element.nested && !element.noNeed && element.input !== "file") {
            if (form[element.nested]) {
              if (!form[element.nested][element.key]) {
                result = false;
                emptys.push(element.label);
              }
            } else {
              result = false;
              emptys.push(element.label);
            }
          } else {
            if (!element.noNeed && element.input !== "file") {
              if (element.key in form) {
                if (!form[element.key]) {
                  result = false;
                  emptys.push(element.label);
                }
              } else {
                result = false;
                emptys.push(element.label);
              }
            }
          }
        }
      });
    return {
      status: result,
      message: emptys,
    };
  };
  const renderError = () => {
    toast.error(`${check()["message"].map((filed) => filed)} اجباری هستند`);
  };
  const dividData = (key) => {
    return (data && data[key]) || [];
  };

  const renderElement = (element) => {
    switch (element.type) {
      case "images":
        return (
          <ImageUploader
            element={element}
            set={(e) => {
              setForm({ ...form, [element.key]: e, previewApi: false });
            }}
            form={form}
            setForm={setForm}
            edit={edit}
          />
        );
      case "select":
        if (element.exist) {
          if (form[element.exist.key] == element.exist.value) {
            return (
              <Select
                element={element}
                handleChange={handleChange}
                handleValue={handleValue}
                loading={loadingNeed}
                data={dividData(element.need) || []}
              />
            );
          } else {
            return <></>;
          }
        }
        if (element.access) {
          if (element.access.includes(user.role)) {
            return (
              <Select
                element={element}
                handleChange={handleChange}
                handleValue={handleValue}
                loading={loadingNeed}
                data={dividData(element.need) || []}
              />
            );
          } else {
            return <></>;
          }
        } else {
          return (
            <Select
              element={element}
              handleChange={handleChange}
              handleValue={handleValue}
              loading={loadingNeed}
              data={dividData(element.need) || []}
            />
          );
        }

      case "textarea":
        return (
          <div className="form-group">
            <label htmlFor="exampleTextarea1">{element.label}</label>
            <textarea
              onChange={({ target: { value } }) => {
                handleChange(element.key, value, element.nested);
              }}
              value={handleValue(element.key, element.nested)}
              className="form-control"
              id="exampleTextarea1"
              placeholder={element.label}
              rows={4}
              style={{ minHeight: "10rem" }}
            />
          </div>
        );
      case "keywords":
        return <Keywords element={element} form={form} setForm={setForm} />;
      case "editor":
        return (
          <CheckEditor
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
          />
        );
      case "multiselect":
        return (
          <MultiSelect
            loading={loadingNeed}
            data={dividData(element.need) || []}
            handleChange={handleChange}
            element={element}
            handleValue={handleValue}
          />
        );
      case "toggle":
        return (
          <div class="form-check form-switch">
            <input
              class="form-check-input"
              id="flexSwitchCheckDefault"
              type="checkbox"
              checked={handleValue(element.key) == "true"}
              onChange={() => {
                handleChange(
                  element.key,
                  handleValue(element.key) == "true" ? "false" : "true"
                );
              }}
            />
            <label class="form-check-label" for="flexSwitchCheckDefault">
              {element.label}
            </label>
          </div>
        );
      case "date":
        return (
          <DatePicker
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            setForm={setForm}
            single={element.single}
          />
        );
      case "file":
        return (
          <FileUploader
            element={element}
            handleChange={handleChange}
            handleValue={handleValue}
            form={form}
            setForm={setForm}
          />
        );
      default:
        if (element.exist) {
          if (form[element.exist.key] == element.exist.value) {
            return (
              <div className="form-group my-2">
                <label htmlFor="exampleInputName1">{element.label}</label>
                <input
                  onChange={({ target: { value } }) => {
                    handleChange(element.key, value, element.nested);
                  }}
                  value={handleValue(element.key, element.nested)}
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  placeholder={element.label}
                />
              </div>
            );
          } else {
            return <></>;
          }
        } else {
          if (element.notExist) {
            if (form[element.notExist.key] != element.notExist.value) {
              return (
                <div className="form-group my-2">
                  <label htmlFor="exampleInputName1">{element.label}</label>
                  <input
                    onChange={({ target: { value } }) => {
                      handleChange(element.key, value, element.nested);
                    }}
                    value={handleValue(element.key, element.nested)}
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder={element.label}
                  />
                </div>
              );
            } else {
              return <></>;
            }
          } else {
            return (
              <div className="form-group my-2">
                <label htmlFor="exampleInputName1">{element.label}</label>
                <input
                  onChange={({ target: { value } }) => {
                    handleChange(element.key, value, element.nested);
                  }}
                  value={handleValue(element.key, element.nested)}
                  type="text"
                  className="form-control"
                  id="exampleInputName1"
                  placeholder={element.label}
                />
              </div>
            );
          }
        }
    }
  };
  return {
    setForm,
    submit,
    loading,
    form,
    setForm,
    handleChange,
    handleValue,
    check,
    getNesdata,
    data,
    loadingNeed,
    renderElement,
    loadingEdit,
  };
};
