import React from "react";
import { useMainContext } from "../../Context";
import Table from "../Table";
import Admin from "./Admin";
import CardTable from "./Components/CardTable";
import Plot from "./Components/Plots";
import Results from "./Components/Results";
import Expertor from "./Expertor";
import { useDashboard } from "./Hooks";
import Manager from "./Manager";

const Dashboard = () => {
  const {
    state: { user },
  } = useMainContext();
  switch (user.role) {
    case "admin":
      return <Admin />;
    case "sales_manager":
      return <Manager />;
    case "sales_expert":
      return <Expertor />;
    default:
      break;
  }
};

export default Dashboard;
