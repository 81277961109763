import React from "react";
import Table from "../Table";
import CardTable from "./Components/CardTable";
import Plot from "./Components/Plots";
import Results from "./Components/Results";
import { useDashboard } from "./Hooks";
const Admin = () => {
  const { data, loading } = useDashboard({ api: { route: "/dashboard" } });
  if (loading) {
    return <>Loading ...</>;
  }
  return (
    <>
      <div className="row g-3 mb-3">
        <CardTable data={data} />
      </div>
      <div className="row mb-3">
        <div className="col">
          <Plot label="درآمد هفتگی" data={data.chart} />
        </div>
      </div>
      <div className="row mb-3">
        <Results data={data} />
      </div>
      <dib className="row">
        <Table
          options={{
            dashboard: { data: data.last_users },
            header: [
              {
                key: "fullname",
                label: "نام",
              },

              {
                key: "mobile",
                label: "موبایل",
              },
              {
                key: "status",
                type: "status",
                label: "وضعیت",
              },
              {
                key: "role",
                label: "نوع کاربر",
                type: "enum",
                enum: {
                  admin: "ادمین",
                  user: "کاربر",
                  sales_manager: "مدیر فروش",
                  sales_expert: "کارشناس فروش",
                },
                default: "user",
              },
              {
                key: "created_at",
                label: "تاریخ ایجاد",
                type: "date",
              },
              {
                key: "updated_at",
                type: "date",
                label: "آخرین ویرایش",
              },
            ],
            api: {},
            label: "آخرین کاربران",
          }}
        />
      </dib>
    </>
  );
};

export default Admin;
