import { useState } from "react";
import { useSearchObject } from "../../../Hooks";
import {
  cutText,
  timeSince,
  ToDateJalali,
  ToTimeJalali,
} from "../../../Utils/functions";
import { BiShow } from "react-icons/bi";
import { BsArrowLeftCircleFill, BsReply, BsToggles } from "react-icons/bs";
import { FaTrash } from "react-icons/fa";
import { MdModeEditOutline, MdPayment, MdTimer } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import { useMainContext } from "../../../Context";
import { AiOutlinePlus } from "react-icons/ai";
import { FiGift } from "react-icons/fi";

export const useTable = ({
  route = "",
  inint = {},
  selectAble = {},
  filters = [],
  dashboard = {},
}) => {
  const [data, setData] = useState({});
  const [selected, setSelected] = useState([]);
  const [loading, setLoading] = useState(true);
  const [LoadingDelete, setLoadingDelete] = useState(false);
  const [areUSure, setAreUSure] = useState({
    id: null,
    status: false,
    data: {},
    type: null,
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const { findNestedObj } = useSearchObject();
  const { post } = useMainContext();

  const getData = async (props) => {
    setLoading(true);
    const { data } = await post(route, {
      ...inint,
      ...props,
      ...createObjFilter(),
    });
    setData(data || { data: [] });
    setLoading(false);
  };
  const createObjFilter = () => {
    let obj = {};
    filters.map((element) => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    return obj;
  };
  const changeObj = (key, value) => {
    let obj = {};
    filters.map((element) => {
      if (searchParams.get(element.key)) {
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
      }
    });
    setSearchParams({ ...obj, [key]: value });
  };
  const filterDelete = (key) => {
    let obj = {};
    filters.map((element) => {
      if (element.key !== key)
        obj = { ...obj, [element.key]: searchParams.get(element.key) };
    });
    setSearchParams({ ...obj });
  };
  const deleteRow = async (data, inint = {}) => {
    setLoadingDelete(true);
    await post(
      data.action.route,
      { [data.action.key]: data.id, ...inint },
      true
    );
    setLoadingDelete(false);
    setSelected([]);
    setAreUSure({ status: false });
  };

  const renderCloumn = (row, col) => {
    switch (col.type) {
      case "date":
        return row && timeSince(row[col.key]);
      case "long":
        return row && cutText(row[col.key]);
      case "status":
        return row && row[col.key] ? "active" : "deative";
      case "enum":
        return row && row[col.key] && col.enum[row[col.key]]
          ? col.enum[row[col.key]]
          : col.default;
      case "zero":
        return row && row[col.key] ? col.enum[0] : col.enum[1];

      case "nested":
        return (
          row &&
          findNestedObj(row[col.objectKey], col.key) &&
          findNestedObj(row[col.objectKey], col.key)[col.key]
        );
      case "exact-date":
        return ToDateJalali(row[col.key]);
      default:
        return row && row[col.key];
    }
  };
  const renderAction = (action, row) => {
    let props = {
      style: {
        marginRight: "10px",
      },
      color: "#a1a5b7",
    };
    switch (action.type) {
      case "edit":
        return (
          <Link
            to="/"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}>
            <MdModeEditOutline {...props} />
          </Link>
        );
      case "show":
        return (
          <Link
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            to={
              `${action.link}/${action.keyGo ? row[action.keyGo] : row.id}` ||
              "/"
            }>
            <BiShow {...props} />
          </Link>
        );
      case "delete":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                type: "delete",
                action,
              });
            }}>
            <FaTrash {...props} />
          </button>
        );
      case "toggle":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "toggle",
              });
            }}>
            <BsToggles {...props} />
          </button>
        );
      case "gift":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "gift",
              });
            }}>
            <FiGift {...props} />
          </button>
        );
      case "select":
        return (
          <button
            className=" p-0 m-0"
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            style={{ border: "none", backgroundColor: "transparent" }}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "select",
              });
            }}>
            <BsToggles {...props} />
          </button>
        );
      case "go":
        return <BsArrowLeftCircleFill {...props} />;
      case "payment":
        return <MdPayment {...props} />;
      case "time":
        return <MdTimer {...props} />;
      case "add":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "add",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}>
            <AiOutlinePlus {...props} />
          </button>
        );
      case "reply":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "reply",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}>
            <BsReply {...props} />{" "}
          </button>
        );
      case "report":
        return (
          <button
            data-toggle="tooltip"
            data-placement="top"
            title={action.label}
            onClick={() => {
              setAreUSure({
                status: true,
                id: row.id,
                data: row,
                action,
                type: "report",
              });
            }}
            className=" p-0 m-0"
            style={{ border: "none", backgroundColor: "transparent" }}>
            <AiOutlinePlus {...props} />
          </button>
        );
      default:
        break;
    }
  };
  const isIn = (id) => {
    return selected.includes(id);
  };
  const toggle = (id) => {
    if (isIn(id)) {
      setSelected([...selected.filter((item) => item !== id)]);
    } else {
      setSelected([...selected, id]);
    }
  };
  const toggleAll = () => {
    if (isSelectedAll()) {
      setSelected([]);
    } else {
      setSelected([...data.data.map((item) => item.id)]);
    }
  };
  const isSelectedAll = () => {
    return selected.length == data?.data.length;
  };
  const length = () => {
    return selected.length;
  };

  return {
    renderCloumn,
    loading,
    getData,
    data,
    renderAction,
    areUSure,
    setAreUSure,
    LoadingDelete,
    deleteRow,
    isIn,
    toggle,
    toggleAll,
    isSelectedAll,
    length,
    selected,
    searchParams,
    setSearchParams,
    changeObj,
    filterDelete,
  };
};
