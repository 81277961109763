import { createContext, useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { toast } from "react-toastify";
import { serialize } from "object-to-formdata";
import axios from "axios";
import { API_SERVER } from "../Server";
axios.defaults.headers.post["Accept"] = "application/json";
axios.defaults.headers.post["Content-Type"] = "multipart/form-data";

const context = createContext();

const ContextProvider = ({ children }) => {
  const initialState = {
    user: JSON.parse(localStorage.getItem("user")) || {},
  };
  const [state, setState] = useState(initialState);
  const user = JSON.parse(localStorage.getItem("user"));
  console.log("s", user);
  if (user && user.access_token) {
    axios.defaults.headers.post[
      "Authorization"
    ] = `Bearer ${user.access_token}`;
  }
  const server = axios.create({
    baseURL: API_SERVER,
  });
  const dispatch = (key, value) => {
    if (value) {
      setState({ ...state, [key]: value });
      localStorage.setItem(key, JSON.stringify(value));
    } else {
      const newState = { ...state };
      delete newState[key];
      setState({ newState });
      localStorage.removeItem(key);
    }
  };
  const post = async (url, body, message = false) => {
    try {
      const response = await server.post(url, serialize(body));
      if (message && response.data.message) {
        toast.success(response.data.message);
      }
      return {
        data: response.data.data,
        status: response.status,
      };
    } catch (error) {
      const {
        response: { status, data },
      } = error;
      switch (+status) {
        case 400:
          toast.error(data.message);
          break;
        case 401:
          localStorage.clear();
          window.location.replace("/");
          toast.error(data.message);
          break;
        case 500:
          toast.error(data.message);
        default:
          break;
      }
      return {};
    }
  };
  const logout = async () => {
    const { data, status } = await post("/logout");
    if (+status === 200) {
      localStorage.removeItem("user");
      window.location.replace("/");
    }
  };
  return (
    <context.Provider value={{ state, dispatch, post, logout }}>
      <Helmet>
        {/* <script src={"/assets/js/moment-range.js"}></script> */}
        {/* <script src={"/vendors/popper/popper.min.js"}></script> */}
        <script src={"/vendors/bootstrap/bootstrap.min.js"}></script>
        {/* <script src={"/vendors/anchorjs/anchor.min.js"}></script>
        <script src={"/vendors/is/is.min.js"}></script>
        <script src={"/vendors/chart/chart.min.js"}></script>
        <script src={"/vendors/echarts/echarts.min.js"}></script>
        <script src={"/vendors/dayjs/dayjs.min.js"}></script>
        <script src={"/assets/js/jdate.min.js"}></script>
        <script src={"/assets/js/dayjs.local-fa.js"}></script>
        <script src={"/assets/js/flatpickr-persian.js"}></script>
        <script src={"/assets/js/flatpickr-persian-fa.js"}></script>
        <script src={"/vendors/lodash/lodash.min.js"}></script>

        <script src={"/vendors/list.js/list.min.js"}></script>
        <script src={"/vendors/choices/choices.min.js"}></script>
        <script src={"/vendors/prism/prism.js"}></script>
        <script src={"/assets/js/flatpickr.full.js"}></script>
        <script src={"/assets/js/flatpickr.local-fa.js"}></script> */}
        <script src={"/assets/js/theme.js"}></script>
        <script src={"/vendors/fontawesome/all.min.js"}></script>
      </Helmet>
      {children}
    </context.Provider>
  );
};

export default ContextProvider;
export const useMainContext = () => {
  return useContext(context);
};
