import React from "react";

const Results = ({ data }) => {
  return (
    <div className="col-12">
      <div className="card py-3 ">
        <div className="card-body py-2">
          <div className="row g-0">
            <div className="col-6 col-md-4 border-200 border-bottom border-end pb-4">
              <h6 className="pb-1 text-700">داده های تمام شده</h6>
              <p className="font-sans-serif lh-1 mb-1 fs-2">
                {data?.count_donecrm}
              </p>
            </div>
            <div className="col-6 col-md-4 border-200 border-md-200 border-bottom border-md-end pb-4 ps-3">
              <h6 className="pb-1 text-700">تعداد داده های بدون کارشناس</h6>
              <p className="font-sans-serif lh-1 mb-1 fs-2">
                {data?.count_crm_not_experter}
              </p>
            </div>
            <div className="col-6 col-md-4 border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3">
              <h6 className="pb-1 text-700">تعداد آخرین گزارشات</h6>
              <p className="font-sans-serif lh-1 mb-1 fs-2">
                {data?.last_reports}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Results;
