import React from "react";
import { cutText, ToDateJalali, ToTimeJalali } from "../../Utils/functions";
import { useOrderDetails } from "./Hooks";

const OrderDetails = ({ option }) => {
  const { data, loading, id } = useOrderDetails(option);
  const renderStatus = (data) => {
    switch (data?.order?.status) {
      case "success":
        return {
          component: () => {
            return (
              <div className="badge rounded-pill badge-soft-success fs--2">
                تکمیل شد
                <span
                  className="fas fa-check ms-1"
                  data-fa-transform="shrink-2"
                />
              </div>
            );
          },
          status: true,
        };
      case "waittopey":
        return {
          component: () => {
            return (
              <div className="badge rounded-pill badge-soft-warning text-dark fs--5">
                <span className="badge badge rounded-pill d-block text-danger ">
                  منتظر پرداخت
                  <svg
                    className="svg-inline--fa fa-redo fa-w-16 ms-1"
                    data-fa-transform="shrink-2"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="redo"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg
                    style={{ transformOrigin: "0.5em 0.5em" }}>
                    <g transform="translate(256 256)">
                      <g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)">
                        <path
                          fill="currentColor"
                          d="M500.33 0h-47.41a12 12 0 0 0-12 12.57l4 82.76A247.42 247.42 0 0 0 256 8C119.34 8 7.9 119.53 8 256.19 8.1 393.07 119.1 504 256 504a247.1 247.1 0 0 0 166.18-63.91 12 12 0 0 0 .48-17.43l-34-34a12 12 0 0 0-16.38-.55A176 176 0 1 1 402.1 157.8l-101.53-4.87a12 12 0 0 0-12.57 12v47.41a12 12 0 0 0 12 12h200.33a12 12 0 0 0 12-12V12a12 12 0 0 0-12-12z"
                          transform="translate(-256 -256)"
                        />
                      </g>
                    </g>
                  </svg>
                  {/* <span class="ms-1 fas fa-redo" data-fa-transform="shrink-2"></span> Font Awesome fontawesome.com */}
                </span>
              </div>
            );
          },
          status: false,
        };
      default:
        return {
          component: () => {
            return (
              <div className="badge rounded-pill badge-soft-danger text-dark fs--5">
                <span className="badge badge rounded-pill d-block text-danger ">
                  ناموفق
                  <svg
                    className="svg-inline--fa fa-ban fa-w-16 ms-1"
                    data-fa-transform="shrink-2"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="ban"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                    data-fa-i2svg
                    style={{ transformOrigin: "0.5em 0.5em" }}>
                    <g transform="translate(256 256)">
                      <g transform="translate(0, 0)  scale(0.875, 0.875)  rotate(0 0 0)">
                        <path
                          fill="currentColor"
                          d="M256 8C119.034 8 8 119.033 8 256s111.034 248 248 248 248-111.034 248-248S392.967 8 256 8zm130.108 117.892c65.448 65.448 70 165.481 20.677 235.637L150.47 105.216c70.204-49.356 170.226-44.735 235.638 20.676zM125.892 386.108c-65.448-65.448-70-165.481-20.677-235.637L361.53 406.784c-70.203 49.356-170.226 44.736-235.638-20.676z"
                          transform="translate(-256 -256)"
                        />
                      </g>
                    </g>
                  </svg>
                  {/* <span class="ms-1 fas fa-redo" data-fa-transform="shrink-2"></span> Font Awesome fontawesome.com */}
                </span>
              </div>
            );
          },
          status: true,
        };
        break;
    }
  };
  if (loading) {
    return <h3 className="text-center">Loading ...</h3>;
  }
  return (
    <div className="content">
      <div className="card mb-3">
        <div
          className="bg-holder d-none d-lg-block bg-card"
          style={{
            backgroundImage:
              "url(../../../assets/img/icons/spot-illustrations/corner-4.png)",
            opacity: "0.7",
          }}
        />
        {/*/.bg-holder*/}
        <div className="card-body position-relative">
          <h5>جزئیات سفارش: #{id}</h5>
          <p className="fs--1">{ToTimeJalali(data?.order?.created_at)}</p>
          <div>
            <strong className="me-2">وضعیت:</strong>

            {renderStatus(data).component()}
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <div className="row">
            <div className="col-md-6 col-lg-4 mb-4 mb-lg-0">
              <h5 className="mb-3 fs-0">مشخصات کاربر</h5>

              <h6 className="mb-2">
                نام و نام خانوادگی : {data?.user?.fullname}
              </h6>
              <h6 className="mb-2">
                ایمیل : {data?.user?.email || "ثبت نشده است"}
              </h6>
              <p className="mb-0 fs--1">
                <a href={`tel:+${data?.user?.mobile}`}>
                  شماره تلفن :{data?.user?.mobile}
                </a>
              </p>
            </div>
            {renderStatus(data)["status"] && (
              <div className="col-md-6 col-lg-4">
                <h5 className="mb-3 fs-0">روش پرداخت</h5>
                <span className="badge badge-soft-success">آنلاین</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mb-3">
        <div className="card-body">
          <div className="table-responsive fs--1">
            <table className="table table-striped border-bottom">
              <thead className="bg-200 text-900">
                <tr>
                  <th className="border-0">محصولات</th>
                  <th className="border-0 text-center">تعداد</th>
                  <th className="border-0 text-end">نرخ</th>
                  <th className="border-0 text-end">میزان</th>
                </tr>
              </thead>
              <tbody>
                <tr className="border-200">
                  <td className="align-middle">
                    <h6 className="mb-0 text-nowrap">{data?.cource?.title}</h6>
                    <p className="mb-0">
                      {cutText(data?.cource?.description, 60)}
                    </p>
                  </td>
                  <td className="align-middle text-center">1</td>
                  <td className="align-middle text-end">
                    {data?.cource?.price} تومان
                  </td>
                  <td className="align-middle text-end">
                    {data?.order?.growth_price} تومان
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          {/* <div className="row g-0 justify-content-end">
            <div className="col-auto">
              <table className="table table-sm table-borderless fs--1 text-end">
                <tbody>
                  <tr>
                    <th className="text-900">جمع فرعی:</th>
                    <td className="fw-semi-bold">6230 تومان</td>
                  </tr>
                  <tr>
                    <th className="text-900">مالیات 5 درصد:</th>
                    <td className="fw-semi-bold">311 تومان</td>
                  </tr>
                  <tr className="border-top">
                    <th className="text-900">جمع:</th>
                    <td className="fw-semi-bold">6541 تومان</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default OrderDetails;
