import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useMainContext } from "../../../Context";

export const useOrderDetails = ({ api, key }) => {
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [loadingBtn, setloadingBtn] = useState(false);
  const {
    post,
    state: { user },
  } = useMainContext();
  useEffect(() => {
    getData();
  }, [loadingBtn]);
  const getData = async () => {
    setLoading(true);
    const { data } = await post(api?.route, { [key]: id });
    console.log("d" , data)
    if (Array.isArray(data)) {
      setData(data);
    } else {
      setData(data);
    }
    setLoading(false);
  };

  const sendData = async report_id => {
    setloadingBtn(report_id);
    await post("/crm/report_delete", { report_id });
    setloadingBtn(false);
  };

  return {
    loading,
    data,
    id,
    sendData,
    loadingBtn,
    user,
  };
};
