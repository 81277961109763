import React from "react";
import { Link } from "react-router-dom";
import { useMainContext } from "../../Context";

const Navbar = () => {
  const { state } = useMainContext();
  console.log("asdasdasdasd ", state.user);
  return (
    <nav className="navbar navbar-light navbar-glass navbar-top navbar-expand">
      <button
        className="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarVerticalCollapse"
        aria-controls="navbarVerticalCollapse"
        aria-expanded="false"
        aria-label="Toggle Navigation">
        <span className="navbar-toggle-icon">
          <span className="toggle-line" />
        </span>
      </button>
      <Link
        to="/dashboard"
        className="navbar-brand me-1 me-sm-3"
        href="index.html">
        <div className="d-flex align-items-center">
          <img
            className="me-2"
            src={
              window.origin + "/assets/img/icons/spot-illustrations/logo.png"
            }
            alt
            width={40}
          />
          <span className="font-sans-serif">نیما کوچکی</span>
        </div>
      </Link>
      <ul className="navbar-nav align-items-center d-none d-lg-block">
        <li className="nav-item">
          <div className="mt-3">
            <p style={{ fontSize: "15px" }}>
              سلام {state?.user?.user?.fullname} عزیز !
            </p>
          </div>
        </li>
      </ul>
      <ul className="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
        <li className="nav-item dropdown">
          <a
            className="nav-link pe-0"
            id="navbarDropdownUser"
            href="#"
            role="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false">
            <div className="avatar avatar-xl">
              <img
                className="rounded-circle"
                src={state?.user?.user?.photo}
                alt
              />
            </div>
          </a>
          <div
            className="dropdown-menu dropdown-menu-end py-0"
            aria-labelledby="navbarDropdownUser">
            <div className="bg-white dark__bg-1000 rounded-2 py-2">
              <a className="dropdown-item fw-bold text-warning" href="#!">
                <svg
                  className="svg-inline--fa fa-crown fa-w-20 me-1"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fas"
                  data-icon="crown"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 640 512"
                  data-fa-i2svg>
                  <path
                    fill="currentColor"
                    d="M528 448H112c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h416c8.8 0 16-7.2 16-16v-32c0-8.8-7.2-16-16-16zm64-320c-26.5 0-48 21.5-48 48 0 7.1 1.6 13.7 4.4 19.8L476 239.2c-15.4 9.2-35.3 4-44.2-11.6L350.3 85C361 76.2 368 63 368 48c0-26.5-21.5-48-48-48s-48 21.5-48 48c0 15 7 28.2 17.7 37l-81.5 142.6c-8.9 15.6-28.9 20.8-44.2 11.6l-72.3-43.4c2.7-6 4.4-12.7 4.4-19.8 0-26.5-21.5-48-48-48S0 149.5 0 176s21.5 48 48 48c2.6 0 5.2-.4 7.7-.8L128 416h384l72.3-192.8c2.5.4 5.1.8 7.7.8 26.5 0 48-21.5 48-48s-21.5-48-48-48z"
                  />
                </svg>
                {/* <span class="fas fa-crown me-1"></span> Font Awesome fontawesome.com */}
                <span>اشتراک ویژه</span>
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="#!">
                تنظیم وضعیت
              </a>
              <a className="dropdown-item" href="pages/user/profile.html">
                نمایه و حساب کاربری
              </a>
              <a className="dropdown-item" href="#!">
                بازخورد
              </a>
              <div className="dropdown-divider" />
              <a className="dropdown-item" href="pages/user/settings.html">
                تنظیمات
              </a>
              <a className="dropdown-item">خروج</a>
            </div>
          </div>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar;
