import { useEffect } from "react";
import Login from "./Components/Login";
import Panel from "./Components/Panel";
import { useMainContext } from "./Context";
import { caching } from "./Utils/functions";

function App() {
  useEffect(() => {
    caching();
  }, []);
  const { state } = useMainContext();
  if (state?.user?.access_token && state?.user?.user?.role !== "user") {
    return <Panel />;
  } else {
    return <Login />;
  }
}

export default App;
