import React from "react";
import { Helmet } from "react-helmet";
import { Route, Routes } from "react-router-dom";
import { useMainContext } from "../../Context";
import Layout from "../../Layouts";
import { sortSettings } from "../../Utils/functions";
import Dashboard from "../Dashboard";
import Form from "../Form";
import NotFound from "../NotFound";
import Support from "../Support";
import Surfaces from "../Surfaces";
import Table from "../Table";
export const stracture = {
  // fas fa-shopping-cart
  pages: [
    {
      label: "داشبورد",
      icon: "fab fa-trello",
      route: "/",
      nested: false,
      access: ["admin", "sales_manager", "sales_expert"],
      options: [
        {
          route: "/",
          type: "dashboard",
          elements: [],
        },
      ],
    },
    {
      icon: "fas fa-shopping-cart",
      nested: false,
      route: "/orders",
      label: "لیست سفارشات",
      access: ["admin", "sales_manager"],
      options: [
        {
          route: "/orders",
          type: "table",
          show: true,
          label: "لیست سفارشات",
          api: {
            route: "/order/list",
          },
          filters: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  label: "موفق",
                  key: "success",
                },
                {
                  label: "در انتظار پرداخت",
                  key: "waittopey",
                },
                {
                  label: "ناموفق",
                  key: "error",
                },
                {
                  label: "رها شده",
                  key: "abandoned",
                },
              ],
            },
          ],
          actions: [
            {
              type: "show",
              link: "/orders",
              label: "جزییات سفارش",
              keyGo: "trackingcode",
            },
          ],
          header: [
            {
              key: "fullname",
              label: "نام",
              type: "nested",
              objectKey: "client",
            },

            {
              key: "mobile",
              label: "موبایل",
              type: "nested",
              objectKey: "client",
            },
            {
              key: "growth_price",
              label: "مبلغ",
            },
            {
              key: "trackingcode",
              label: "کد پیگیری",
            },
            {
              key: "title",
              label: "نام محصول",
              type: "nested",
              objectKey: "cource",
            },
            {
              key: "status",
              label: "وضعیت",
              type: "enum",
              enum: {
                waittopey: "در انتظار پرداخت",
                success: "موفق",
                error: "مشکل پرداخت",
                abandoned: "رها شده",
              },
            },
            {
              key: "created_at",
              label: "زمان",
              type: "date",
            },
          ],
        },
        {
          route: "/orders/:id",
          type: "surface",
          surfaceType: "orderDetails",
          label: "جزیيات سفارش",
          key: "trackingcode",
          api: {
            route: "/order/details",
          },
          header: [],
        },
      ],
    },
    {
      icon: "far fa-comments",
      nested: false,
      route: "/comments",
      label: "نقد و نظرات",
      access: ["admin"],
      options: [
        {
          route: "/comments",
          type: "table",
          show: true,
          label: "",
          api: {
            route: "/comment/adminlist",
          },
          actions: [
            {
              type: "toggle",
              depened: {
                key: "status",
                on: "غیر فعال کردن نظر",
                off: "فعال کردن نظر",
              },
              key: "comment_id",
              route: "/comment/toggle",
            },
            {
              type: "delete",
              label: "حذف اطلاعیه",
              route: "/comment/delete",
              key: "comment_id",
            },
          ],
          header: [
            {
              key: "fullname",
              label: "نام",
              type: "nested",
              objectKey: "commenter",
            },

            {
              key: "mobile",
              label: "موبایل",
              type: "nested",
              objectKey: "commenter",
            },
            {
              key: "status",
              type: "enum",
              label: "وضعیت",
              enum: {
                on: "فعال",
                off: "غیر فعال",
              },
            },
            {
              key: "rel_type",
              type: "enum",
              label: "نوع",
              enum: {
                Blog: "مقاله",
                Cource: "دوره",
              },
            },
            {
              key: "title",
              label: "برای",
              type: "nested",
              objectKey: "rel",
            },
            {
              key: "isreply",
              label: "پاسخ",
              type: "zero",
              enum: ["منتظر پاسخ", "پاسخ داده شده"],
            },
            {
              key: "user_type",
              label: "نوع کاربر ",
              type: "enum",
              enum: {
                admin: "ادمین",
                user: "کاربر",
                sales_manager: "مدیر فروش",
                sales_expert: "کارشناس فروش",
              },
            },
            {
              key: "text",
              type: "long",
              label: "متن",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
          ],
        },
      ],
    },
    {
      icon: "fas fa-bell",
      nested: true,
      label: "اطلاعیه ها",
      access: ["admin"],
      options: [
        {
          route: "/notif/create",
          type: "form",
          show: true,
          label: "ایجاد اطلاعیه",
          api: {
            route: "/notif/store",
          },
          box: {
            route: "/notif/list",
            title: "لیست اطلاعیه ها",
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "content",
              label: "متن",
              type: "textarea",
            },
            {
              key: "type",
              label: "برای",
              type: "select",
              options: [
                {
                  name: "کاربر",
                  key: "user",
                },
                {
                  name: "مدیر فروش",
                  key: "sales_manager",
                },
                {
                  name: "کارشناس فروش",
                  key: "sales_expert",
                },
                {
                  name: "همه",
                  key: "all",
                },
              ],
            },
            {
              key: "exp",
              label: "تاریخ انقضا",
              type: "date",
              single: true,
            },
          ],
        },
        {
          route: "/notif/list",
          type: "table",
          show: true,
          label: "لیست اطلاعیه ها",
          api: {
            route: "/notif/list",
          },
          actions: [
            {
              type: "delete",
              label: "حذف اطلاعیه",
              route: "/notif/delete",
              key: "notif_id",
            },
            {
              type: "show",
              link: "/notif/update",
              label: "show profile",
            },
          ],
          header: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "content",
              label: "محتوا",
              type: "long",
            },
            {
              key: "status",
              type: "status",
              label: "وضعیت",
            },
            {
              key: "type",
              label: "برای ",
              type: "enum",
              enum: {
                admin: "ادمین",
                user: "کاربر",
                sales_manager: "مدیر فروش",
                sales_expert: "کارشناس فروش",
              },
            },
            {
              key: "exp",
              type: "exact-date",
              label: "تاریخ انقضا",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
          ],
        },
        {
          route: "/notif/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش اطلاعیه ها",
          box: {
            title: "لیست اطلاعیه ها",
            route: "/notif/list",
          },
          api: {
            route: "/notif/update",
            show: {
              route: "/notif/show",
              key: "notif_id",
            },
          },
          sortInint: (state) => {
            return {
              ...state,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "content",
              label: "متن",
              type: "textarea",
            },
            {
              key: "type",
              label: "برای",
              type: "select",
              options: [
                {
                  name: "ادمین",
                  key: "admin",
                },
                {
                  name: "کاربر",
                  key: "user",
                },
                {
                  name: "مدیر فروش",
                  key: "sales_manager",
                },
                {
                  name: "کارشناس فروش",
                  key: "sales_expert",
                },
                {
                  name: "همه",
                  key: "all",
                },
              ],
            },
            {
              key: "exp",
              label: "تاریخ انقضا",
              type: "date",
              single: true,
            },
          ],
        },
      ],
    },
    {
      label: "کاربران",
      nested: true,
      icon: "far fa-user",
      access: ["admin", "sales_manager"],
      options: [
        {
          route: "/user/create",
          type: "form",
          show: true,

          label: "ایجاد کاربر",
          api: {
            route: "/client/store",
          },
          box: {
            route: "/user/list",
            title: "لیست کاربران",
          },
          form: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "email",
              label: "ایمیل",
              noNeed: true,
            },
            {
              key: "role",
              noNeed: true,
              label: "نوع کاربر",
              access: ["admin"],
              type: "select",
              options: [
                {
                  name: "کاربر",
                  key: "user",
                },
                {
                  name: "ادمین",
                  key: "admin",
                },
                {
                  name: "مدیر فروش",
                  key: "sales_manager",
                },
                {
                  name: "کارشناس فروش",
                  key: "sales_expert",
                },
              ],
            },
            {
              key: "photo",
              label: "عکس پروفایل",
              type: "images",
              noNeed: true,
            },
            {
              key: "password",
              label: "رمز عبور",
              noNeed: true,
              notExist: {
                key: "role",
                value: "user",
              },
            },
          ],
        },
        {
          route: "/user/list",
          type: "table",
          show: true,
          label: "لیست کاربران",
          api: {
            route: "/client/list",
          },
          filters: [
            {
              key: "role",
              type: "select",

              options: [
                {
                  label: "ادمین",
                  key: "admin",
                },
                {
                  label: "کاربر",
                  key: "user",
                },
                {
                  label: "مدیر فروش",
                  key: "sales_manager",
                },
                {
                  label: "کارشناس فروش",
                  key: "sales_expert",
                },
              ],
            },
          ],
          actions: [
            {
              type: "delete",
              label: "حذف کاربر",
              route: "/client/delete",
              key: "user_id",
            },
            {
              type: "toggle",
              label: "تغییر وضعیت کاربر",
              key: "user_id",
              route: "/client/toggle",
            },
            {
              type: "access",
              label: "generate password",
            },
            {
              type: "show",
              link: "/user/update",
              label: "show profile",
            },
          ],
          header: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "status",
              type: "enum",
              label: "وضعیت",
              enum: {
                true: "فعال",
                false: "غیر فعال",
                1: "فعال",
                0: "غیر فعال",
              },
            },
            {
              key: "role",
              label: "نوع کاربر",
              noNeed: true,
              access: ["admin"],
              type: "enum",
              enum: {
                admin: "ادمین",
                user: "کاربر",
                sales_manager: "مدیر فروش",
                sales_expert: "کارشناس فروش",
              },
              default: "user",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "آخرین ویرایش",
            },
          ],
        },
        {
          route: "/user/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش کاربر",
          box: {
            title: "لیست کاربران",
            route: "/user/list",
          },
          api: {
            route: "/client/profile/edit",
            show: {
              route: "/client/profile",
              key: "user_id",
            },
          },
          sortInint: (state) => {
            if (state?.photo && state.photo[0]) {
              return {
                ...state,
                photo: state?.photo[0] || "",
                user_id: state.id,
              };
            } else {
              return {
                ...state,
                user_id: state.id,
                photo: {},
              };
            }
          },
          form: [
            {
              key: "fullname",
              label: "نام",
            },
            {
              key: "mobile",
              label: "موبایل",
            },
            {
              key: "email",
              label: "ایمیل",
            },

            {
              key: "role",
              label: "نوع کاربر",
              type: "select",
              access: ["admin"],
              options: [
                {
                  name: "ادمین",
                  key: "admin",
                },
                {
                  name: "کاربر",
                  key: "user",
                },
                {
                  name: "مدیر فروش",
                  key: "sales_manager",
                },
                {
                  name: "کارشناس فروش",
                  key: "sales_expert",
                },
              ],
            },
            {
              key: "photo",
              label: "عکس پروفایل",
              type: "images",
            },
          ],
        },
      ],
    },
    {
      label: "دسته بندی",
      icon: "",
      nested: true,
      access: ["admin"],
      icon: "fas fa-plus-square",
      options: [
        {
          route: "/category/create",
          type: "form",
          show: true,
          label: "ایجاد دسته بندی",
          api: {
            route: "/category/store",
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "type",
              label: "نوع",
              type: "select",
              options: [
                {
                  name: "مقاله",
                  key: "Blog",
                },
                {
                  name: "دوره",
                  key: "Cource",
                },
              ],
            },
            {
              key: "keywords",
              type: "keywords",
              noNeed: true,
              label: "کلمات کلیدی",
              nested: "seo",
            },
            {
              label: "توضیحات صفحه",
              type: "textarea",
              noNeed: true,
              nested: "seo",
              key: "seoDescription",
            },
            {
              key: "image_alt",
              noNeed: true,
              label: "متن جایگزین تصویر",
            },

            {
              key: "image",
              label: "تصویر دسته بندی",
              noNeed: true,
              type: "images",
            },
          ],
        },
        {
          route: "/category/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش دسته بندی",
          api: {
            route: "/category/edit",
            show: {
              route: "/category/show",
              key: "category_id",
            },
          },
          sortInint: (state) => {
            return {
              ...state,
              image_alt: state?.image?.alt || "",
              image: state?.image?.url || false,
              category_id: state.id,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "type",
              label: "نوع",
              type: "select",
              options: [
                {
                  name: "مقاله",
                  key: "Blog",
                },
                {
                  name: "دوره",
                  key: "Media",
                },
              ],
            },
            {
              key: "keywords",
              type: "keywords",
              label: "کلمات کلیدی",
              nested: "seo",
            },
            {
              label: "توضیحات صفحه",
              type: "textarea",
              nested: "seo",
              key: "seoDescription",
            },
            {
              key: "image_alt",
              label: "متن جایگزین تصویر",
            },

            {
              key: "image",
              label: "تصویر دسته بندی",
              type: "images",
            },
          ],
        },
        {
          route: "/category/list",
          type: "table",
          show: true,
          label: "لیست دسته بندی",
          api: {
            route: "/category/list",
          },
          actions: [
            {
              type: "delete",
              label: "delete category",
              route: "/category/delete",
              key: "category_id",
            },
            {
              type: "show",
              label: "edit category",
              link: "/category/update",
            },
          ],
          header: [
            {
              key: "title",
              label: "عنوان",
            },

            {
              key: "type",
              label: "نوع",
              type: "enum",
              enum: { Blog: "مقاله", Cource: "دوره" },
            },
            {
              key: "created_at",
              label: "زمان ایجاد",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "آخرین ویرایش",
            },
          ],
        },
      ],
    },
    {
      label: "دوره ها",
      nested: true,
      access: ["admin"],
      icon: "fas fa-photo-video",
      options: [
        {
          route: "/course/create",
          type: "form",
          show: true,
          label: "ایجاد دوره",
          api: {
            route: "/cource/store",
            needs: ["cource_category"],
          },
          sort: (state) => {
            return {
              ...state,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "category_id",
              label: "دسته بندی دوره",
              type: "select",
              need: "cource_category",
            },
            {
              key: "image",
              label: "تصویر دوره",
              type: "images",
            },
            {
              key: "image_alt",
              label: "متن جایگزین تصویر",
            },
            {
              key: "price",
              label: "قیمت",
            },
            {
              key: "spot_code",
              label: "کد اسپات پلیر",
            },
          ],
        },
        {
          route: "/course/list",
          type: "table",
          show: true,
          label: "لیست دوره ها",
          api: {
            route: "/cource/adminlist",
          },
          actions: [
            {
              type: "delete",
              label: "حذف دوره",
              route: "/cource/delete",
              key: "cource_id",
            },
            {
              type: "show",
              link: "/course/update",
              label: "ویرایش دوره",
            },
            {
              type: "gift",
              route: "/cource/second_price",
              label: "ثبت قیمت دوم",
              key: "cource_id",
            },
          ],
          header: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "price",
              label: "قیمت",
            },
            {
              key: "price",
              label: "تخفیف",
              type: "nested",
              objectKey: "second_price",
            },
            {
              key: "title",
              label: "دسته بندی",
              type: "nested",
              objectKey: "category",
            },
            {
              key: "created_at",
              label: "created_at",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "last upadte",
            },
          ],
        },
        {
          route: "/course/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش دوره",
          api: {
            route: "/cource/update",
            show: {
              route: "/cource/edit",
              key: "cource_id",
            },
            needs: ["cource_category"],
          },
          sortInint: (state) => {
            return {
              ...state,
              image_alt: (state?.image && state?.image.alt) || "",
              image: (state?.image && state?.image.url) || false,
              cource_id: state.id,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "category_id",
              label: "دسته بندی دوره",
              type: "select",
              need: "cource_category",
            },
            {
              key: "image",
              label: "تصویر دوره",
              type: "images",
            },
            {
              key: "image_alt",
              label: "متن جایگزین تصویر",
            },
            {
              key: "price",
              label: "قیمت",
            },
            {
              key: "spot_code",
              label: "کد اسپات پلیر",
            },
          ],
        },
      ],
    },
    {
      label: "مقالات",
      access: ["admin"],
      nested: true,
      icon: "far fa-clone",
      options: [
        {
          route: "/blog/create",
          type: "form",
          show: true,
          label: "ایجاد مقاله",
          api: {
            route: "/blog/store",
            needs: ["tags", "blog_category"],
          },
          sort: (state) => {
            return {
              ...state,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "category_id",
              label: "دسته بندی مقاله",
              type: "select",
              need: "blog_category",
            },
            {
              key: "description",
              label: "توضیحات",
              type: "textarea",
            },
            {
              key: "content",
              label: "متن مقاله",
              type: "editor",
            },
            {
              key: "image",
              label: "تصویر مقاله",
              type: "images",
            },
            {
              key: "image_alt",
              label: "متن جایگزین تصویر",
            },
            {
              key: "tags",
              type: "keywords",
              label: "کلمات کلیدی",
            },
            {
              label: "توضیحات سيو",
              type: "textarea",
              nested: "seo",
              key: "seoDescription",
            },
          ],
        },
        {
          route: "/blog/list",
          type: "table",
          show: true,
          label: "لیست مقالات",
          api: {
            route: "/blog/adminlist",
          },
          actions: [
            {
              type: "delete",
              label: "حذف مقاله",
              route: "/blog/delete",
              key: "blog_id",
            },
            {
              type: "show",
              link: "/blog/update",
              label: "ویرایش مقاله",
            },
          ],
          header: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "fullname",
              label: "نویسنده",
              type: "nested",
              objectKey: "auther",
            },
            {
              key: "title",
              label: "دسته بندی",
              type: "nested",
              objectKey: "category",
            },
            {
              key: "created_at",
              label: "created_at",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "last upadte",
            },
          ],
        },
        {
          route: "/blog/update/:id",
          type: "edit",
          show: false,
          label: "ویرایش مقاله",
          api: {
            route: "/blog/update",
            show: {
              route: "/blog/edit",
              key: "blog_id",
            },
            needs: ["tags", "blog_category"],
          },
          sortInint: (state) => {
            return {
              ...state,
              image_alt: (state?.image[0] && state?.image[0].alt) || "",
              image: (state?.image[0] && state?.image[0].url) || false,
              blog_id: state.id,
            };
          },
          form: [
            {
              key: "title",
              label: "عنوان",
            },
            {
              key: "category_id",
              label: "دسته بندی مقاله",
              type: "select",
              need: "blog_category",
            },
            {
              key: "description",
              label: "توضیحات",
              type: "textarea",
            },
            {
              key: "content",
              label: "متن مقاله",
              type: "editor",
            },
            {
              key: "image",
              label: "تصویر مقاله",
              type: "images",
            },
            {
              key: "image_alt",
              label: "متن جایگزین تصویر",
            },
            {
              key: "tags",
              type: "keywords",
              label: "کلمات کلیدی",
            },
            {
              label: "توضیحات سيو",
              type: "textarea",
              nested: "seo",
              key: "seoDescription",
            },
          ],
        },
      ],
    },
    {
      label: "مدیریت تخفیف ها",
      icon: "fas fa-gift",
      access: ["admin"],
      nested: true,
      options: [
        {
          route: "/offer/create",
          type: "form",
          show: true,
          label: "ایجاد تخفیف",
          api: {
            needs: ["cource"],
            route: "/off/store",
          },
          box: {
            route: "/off/list",
            title: "تخفیف ها",
          },
          form: [
            {
              key: "code",
              label: "کد تخفیف",
            },
            {
              key: "type_discount",
              label: "نوع تخفیف",
              type: "select",
              options: [
                {
                  name: "درصدی",
                  key: "percentage",
                },
                {
                  name: "پولی",
                  key: "money",
                },
              ],
            },
            {
              key: "amount_discount",
              label: "میزان تخفیف",
              effect: {
                percentage: {
                  name: "درصد",
                },
                money: {
                  name: "تومان",
                },
              },
            },
            {
              key: "max_offer_amount",
              label: "حداکثر مقدار تخفیف(تومان)",
              exist: {
                key: "type_discount",
                value: "percentage",
              },
              noNeed: true,
            },
            {
              key: "product_type",
              label: "برای",
              type: "select",
              options: [
                {
                  name: "همه دوره ها",
                  key: "all",
                },
                {
                  name: "دوره خاص",
                  key: "sepcial",
                },
              ],
            },
            {
              key: "cource_id",
              label: "دوره",
              type: "select",
              need: "cource",
              exist: {
                key: "product_type",
                value: "sepcial",
              },
              noNeed: true,
            },
            {
              key: "count_allowed",
              label: "تعداد مجاز استفاده",
            },
            {
              key: ["pro_time", "exp_time"],
              label: "تاریخ شروع و پایان",
              type: "date",
            },
            {
              key: "on_secondprice",
              type: "toggle",
              label: "اعمال تخفیف بر روی قیمت جشنواره",
            },
          ],
        },
        {
          route: "/off/list",
          type: "table",
          show: true,
          label: "تخفیف ها",
          api: {
            route: "/off/list",
          },
          actions: [
            {
              type: "delete",
              label: "حذف تخفیف",
              route: "/off/delete",
              key: "offer_id",
            },
          ],
          header: [
            {
              key: "code",
              label: "کد تخفیف",
            },
            {
              key: "type_discount",
              label: "نوع تخفیف",
              type: "enum",
              enum: {
                percentage: "درصدی",
                money: "پولی",
              },
            },
            {
              key: "amount_discount",
              label: "مقدار",
            },
            {
              key: "pro_time",
              label: "تاریخ شروع",
            },
            {
              key: "exp_time",
              label: "تاریخ پایان",
            },
          ],
        },
      ],
    },
    {
      label: "تنظیمات",
      icon: "fas fa-cogs",
      access: ["admin"],
      nested: true,
      options: [
        {
          route: "/settings/comments",
          type: "edit",
          show: true,
          label: "نظرات",
          api: {
            route: "/setting/update",
            show: {
              route: "/setting/inputanddata",
            },
            initial: { key: "comment" },
          },
          sortInint: (state) => {
            return {
              ...sortSettings(state),
            };
          },
          sort: (state) => {
            return { ...state, key: "comment" };
          },
          form: [
            {
              key: "unkhownblog",
              type: "toggle",
              label: "نظر ناشناس برای مقالات",
            },
            {
              key: "unkhowncource",
              type: "toggle",
              label: "نظر ناشناس برای دوره ها",
            },
            {
              key: "imgagecommect",
              type: "images",
              label: "تصویر ناشناس",
            },
          ],
        },
        {
          route: "/settings/footer",
          type: "edit",
          show: true,
          label: "تنظیمات مدریتی سایت",
          api: {
            route: "/setting/update",
            show: {
              route: "/setting/inputanddata",
            },
            initial: { key: "site" },
          },
          sortInint: (state) => {
            return {
              ...sortSettings(state),
            };
          },
          sort: (state) => {
            return { ...state, key: "site" };
          },
          form: [
            {
              key: "twitter",
              label: "آدرس توییتر",
            },
            {
              key: "instagram",
              label: "آدرس اینستاگرام",
            },
            {
              key: "whatsapp",
              label: "آدرس واتساب",
            },
            {
              key: "telegram",
              label: "تلگرام",
            },
            {
              key: "supportnumber",
              label: "شماره تماس پشتیبانی",
            },
            {
              key: "logo",
              type: "images",
              label: "لوگو سایت",
            },

            {
              key: "aboutmetext",
              label: "متن درمورد من",
              type: "textarea",
            },
            {
              key: "copyright",
              label: "متن کپی رایت",
              type: "textarea",
            },
            {
              key: "accsestempcode",
              label: "تعداد مجاز دریافت کد تایید موقت هر شماره",
            },
            {
              key: "adminsms",
              label: "ارسال اس ام اس های مدیریتی",
              type: "toggle",
            },
            {
              key: "adminsms_numbers",
              label:
                "شماره های مدیریت برای دریافت اس ام های مدیریتی ( جدا کننده , )",
              type: "textarea",
            },
          ],
        },
        {
          route: "/settings/landing",
          type: "edit",
          show: true,
          label: "صفحه اصلی",
          api: {
            route: "/setting/update",
            show: {
              route: "/setting/inputanddata",
            },
            initial: { key: "landing" },
          },
          sortInint: (state) => {
            return {
              ...sortSettings(state),
            };
          },
          sort: (state) => {
            return { ...state, key: "landing" };
          },
          form: [
            {
              key: "count_consultation",
              label: "تعداد مشاوره و شخصیت‌‌‌شناسی",
            },
            {
              key: "count_expertـconsultant",
              label: "تعداد هنرجو هیدروگرافیک",
            },
            {
              key: "count_success_business",
              label: "تعداد هنرجو موفق",
            },
          ],
        },

        // {
        //   route: "/settings/contact-us",
        //   type: "edit",
        //   show: true,
        //   label: "ارتباط با ما",
        //   api: {
        //     route: "/setting/update",
        //     show: {
        //       route: "/setting/inputanddata",
        //     },
        //     initial: { key: "connect_us" },
        //   },
        //   sortInint: (state) => {
        //     return {
        //       ...sortSettings(state),
        //     };
        //   },
        //   sort: (state) => {
        //     return { ...state, key: "connect_us" };
        //   },
        //   form: [
        //     {
        //       key: "supportnumber",
        //       label: "تلفن پشتیبانی",
        //     },
        //     {
        //       key: "logo",
        //       type: "images",
        //       label: "لوگو سایت",
        //     },
        //     {
        //       key: "twitter",
        //       label: "آدرس توییتر",
        //     },
        //     {
        //       key: "instagram",
        //       label: "آدرس اینستاگرام",
        //     },
        //   ],
        // },
        // {
        //   route: "/settings/spot",
        //   type: "edit",
        //   show: true,
        //   label: "لینک های اسپات پلیر",
        //   api: {
        //     route: "/setting/update",
        //     show: {
        //       route: "/setting/inputanddata",
        //     },
        //     initial: { key: "spot" },
        //   },
        //   sortInint: state => {
        //     return {
        //       ...sortSettings(state),
        //     };
        //   },
        //   sort: state => {
        //     return { ...state, key: "spot" };
        //   },
        //   form: [
        //     {
        //       key: "windows",
        //       label: "لینک ویندوز",
        //     },
        //     {
        //       key: "macos",
        //       label: "لینک macos",
        //     },
        //     {
        //       key: "android",
        //       label: "لینک اپلیکیشن اندروید",
        //     },
        //     {
        //       key: "webapp",
        //       label: "لینک وب اپ",
        //     },
        //     {
        //       key: "ubonto",
        //       label: "لینک لینوکس",
        //     },
        //     {
        //       key: "ios",
        //       label: "لینک ios",
        //     },
        //   ],
        // },
      ],
    },
    {
      label: "CRM",
      icon: "fas fa-chart-line",
      access: ["admin", "sales_manager"],
      nested: true,
      options: [
        {
          route: "/crm/list",
          type: "table",
          selectAble: {
            status: true,
            type: "btn",
            text: "تخصیص داده به کارشناس",
            route: "/crm/add_someexperter_manual_somedata",
            statement: { key: "status", value: "noـexpert" },
            subRoute: "/crm/add_experter_auto_somedata",
          },
          filters: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  label: "بدون کارشناس",
                  key: "noـexpert",
                },
                {
                  label: "منتظر بررسی کارشناس",
                  key: "awaiting_review",
                },
                {
                  label: "در حال بررسی",
                  key: "start_procses",
                },
                {
                  label: "بررسی متوقف شده",
                  key: "wait_procses",
                },
                {
                  label: "موفق",
                  key: "successful",
                },
                {
                  label: "ناموفق",
                  key: "Unsuccessful",
                },
                {
                  label: "رد شده",
                  key: "Canceled",
                },
              ],
            },
            {
              route: "/crm/add_experter_auto",
              text: "تخصیص همه ی داده ها به صورت رندوم",
              type: "btn",
              statement: {
                key: "status",
                value: "noـexpert",
              },
            },
          ],
          show: true,
          label: "لیست",
          api: {
            route: "/crm/list_data",
          },
          actions: [
            {
              type: "delete",
              label: "حذف",
              route: "/crm/crm_delete",
              key: "id",
            },
            {
              type: "select",
              label: "تغییر وضعیت",
              route: "/crm/change_status",
              key: "crm_id",
              objectKey: "status",
              options: [
                {
                  label: "بدون کارشناس",
                  key: "noـexpert",
                },
                {
                  label: "منتظر بررسی کارشناس",
                  key: "awaiting_review",
                },
                {
                  label: "در حال بررسی",
                  key: "start_procses",
                },
                {
                  label: "بررسی متوقف شده",
                  key: "wait_procses",
                },
                {
                  label: "موفق",
                  key: "successful",
                },
                {
                  label: "ناموفق",
                  key: "Unsuccessful",
                },
                {
                  label: "رد شده",
                  key: "Canceled",
                },
              ],
            },
            {
              type: "report",
              label: "ثبت گزارش",
              route: "/crm/addreport",
              key: "crm_id",
              objectKey: "note",
            },
            { type: "show", link: "/reports", label: "گزارشات" },
          ],
          header: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },

            {
              key: "status",
              label: "وضعیت",
              type: "enum",
              enum: {
                noـexpert: "بدون کارشناس",
                awaiting_review: "منتظر بررسی کارشناس",
                start_procses: "در حال بررسی",
                wait_procses: "بررسی متوقف شده",
                successful: "موفق",
                Unsuccessful: "ناموفق",
                Canceled: "رد شده",
              },
              default: "user",
            },
            {
              key: "fullname",
              label: "نام کارشناس",
              type: "nested",
              objectKey: "experter",
            },
            {
              key: "change_status_date",
              label: "آخرین تغییر وضعیت",
              type: "date",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "آخرین ویرایش",
            },
          ],
        },
        {
          route: "/crm/add-form",
          type: "form",
          show: true,
          label: "ثبت دستی",
          api: {
            route: "/crm/new_one_data",
          },
          form: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },
          ],
        },
        {
          route: "/crm/add-file",
          type: "form",
          show: true,
          label: "ارسال فایل",
          api: {
            route: "/crm/import_file",
          },
          form: [
            {
              key: "file",
              label: "فایل اکسل",
              type: "file",
            },
          ],
        },
        {
          label: "",
          show: false,
          type: "surface",
          surfaceType: "reports",
          key: "crm_id",
          route: "/reports/:id",
          api: {
            route: "/crm/show_reports",
          },
        },
      ],
    },
    {
      label: "لیست داده ها من",
      route: "/list",
      nested: true,
      icon: "fas fa-chart-line",
      access: ["sales_expert"],

      options: [
        {
          type: "table",
          route: "/list",
          show: true,
          label: "لیست داده های من",
          api: {
            route: "/sexpert/list_data",
          },
          filters: [
            {
              key: "status",
              type: "select",
              options: [
                {
                  label: "منتظر بررسی کارشناس",
                  key: "awaiting_review",
                },
                {
                  label: "در حال بررسی",
                  key: "start_procses",
                },
                {
                  label: "بررسی متوقف شده",
                  key: "wait_procses",
                },
                {
                  label: "موفق",
                  key: "successful",
                },
                {
                  label: "ناموفق",
                  key: "Unsuccessful",
                },
                {
                  label: "رد شده",
                  key: "Canceled",
                },
              ],
            },
          ],
          actions: [
            {
              type: "select",
              label: "تغییر وضعیت",
              route: "/sexpert/change_status",
              key: "crm_id",
              objectKey: "status",
              options: [
                {
                  label: "بدون کارشناس",
                  key: "noـexpert",
                },
                {
                  label: "منتظر بررسی کارشناس",
                  key: "awaiting_review",
                },
                {
                  label: "در حال بررسی",
                  key: "start_procses",
                },
                {
                  label: "بررسی متوقف شده",
                  key: "wait_procses",
                },
                {
                  label: "موفق",
                  key: "successful",
                },
                {
                  label: "ناموفق",
                  key: "Unsuccessful",
                },
                {
                  label: "رد شده",
                  key: "Canceled",
                },
              ],
            },
            {
              type: "report",
              label: "ثبت گزارش",
              route: "/sexpert/add_Report",
              key: "crm_id",
              objectKey: "note",
            },
            { type: "show", link: "/reports", label: "گزارشات" },
          ],
          header: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },

            {
              key: "status",
              label: "وضعیت",
              type: "enum",
              enum: {
                noـexpert: "بدون کارشناس",
                awaiting_review: "منتظر بررسی کارشناس",
                start_procses: "در حال بررسی",
                wait_procses: "بررسی متوقف شده",
                successful: "موفق",
                Unsuccessful: "ناموفق",
                Canceled: "رد شده",
              },
              default: "user",
            },
            {
              key: "change_status_date",
              label: "آخرین تغییر وضعیت",
              type: "date",
            },
            {
              key: "created_at",
              label: "تاریخ ایجاد",
              type: "date",
            },
            {
              key: "updated_at",
              type: "date",
              label: "آخرین ویرایش",
            },
          ],
        },
        {
          route: "/crm/add-form",
          type: "form",
          show: true,
          label: "ثبت دستی",
          api: {
            route: "/sexpert/store_date",
          },
          form: [
            {
              key: "fullname",
              label: "نام",
            },

            {
              key: "mobile",
              label: "موبایل",
            },
          ],
        },
        {
          label: "",
          show: false,
          type: "surface",
          surfaceType: "reports",
          key: "crm_id",
          route: "/reports/:id",
          api: {
            route: "/sexpert/reports",
          },
        },
      ],
    },
  ],
};
const Panel = () => {
  const renderRoute = (option) => {
    switch (option.type) {
      case "form":
        return (
          <Route
            path={option.route}
            element={
              <Layout stracture={stracture}>
                <Form options={option} />
              </Layout>
            }
          />
        );
      case "edit":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Form options={option} edit={true} />
              </Layout>
            }
          />
        );
      case "table":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Table options={option} />
              </Layout>
            }
          />
        );
      case "dashboard":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Dashboard />
              </Layout>
            }
          />
        );
      case "ticketing":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Support />
              </Layout>
            }
          />
        );
      case "surface":
        return (
          <Route
            path={option.route}
            element={
              <Layout>
                <Surfaces option={option} />
              </Layout>
            }
          />
        );

      default:
        break;
    }
  };
  const {
    state: { user },
  } = useMainContext();
  return (
    <>
      <Routes>
        <Route path="*" element={<NotFound />} />
        {stracture &&
          stracture.pages.map((page) => {
            if (page.access.includes(user.role)) {
              return page.options.map((option) => {
                if (option) {
                  return renderRoute(option);
                }
              });
            }
          })}
      </Routes>
    </>
  );
};

export default Panel;
